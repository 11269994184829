import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import { UserDetails } from 'src/app/model/user';
import { AuthService } from 'src/app/services/auth.service';
import { UserServiceService } from 'src/app/services/user-service.service';
import { VerifyOtpComponent } from '../modal/verify-otp/verify-otp.component';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  subscribeUserDetails: any;
  userDetails: UserDetails = new UserDetails();
  recaptchaVerifier: firebase.auth.RecaptchaVerifier= {} as  firebase.auth.RecaptchaVerifier;
  phoneNo: any={} as any;
  countryCode: any = '+91';
  password: string='';
  constructor(private authService: AuthService,
    private alertController: AlertController,
    private router: Router,
    private modalController: ModalController,
    private userService: UserServiceService,
    private afAuth: AngularFireAuth


  ) { }

  ngOnInit() { }
  signinWithPhoneNumber($event:any) {
    if (this.phoneNo && this.countryCode) {
      this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
        this.authService.signInWithPhoneNumber(this.recaptchaVerifier, this.countryCode + this.phoneNo).then(success => {
          this.userDetails.mobileNo = this.countryCode + this.phoneNo;
          this.userService.setUserDetails(this.userDetails);
          this.onClickVerify();
        });
      })

    }
  }



  async ionViewDidEnter() {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      size: 'invisible',
      callback: (response:any) => {

      },
      'expired-callback': () => {
      }
    });
  }
  ionViewDidLoad() {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      size: 'invisible',
      callback: (response:any) => {
        console.log('resp', response);


      },
      'expired-callback': (exp:any) => {
        console.log('exp', exp);
      }
    });
  }

  async onClickVerify() {
    const modal = await this.modalController.create({
      component: VerifyOtpComponent,
      cssClass: 'centerModal_2',
      backdropDismiss: false,
      componentProps: {
        recaptcha: this.recaptchaVerifier
      },
    });
    await modal.present();
  }
}
