<ion-content>
  <div class="inner-page">
    <div>
      <div class="logo">
        <ion-img [src]="'/assets/images/logo.svg'"></ion-img>
      </div>
      <!-- <div [innerHTML]="welcomeInfo.data">

      </div> -->
      <div>
        <h4>Welcome !</h4>
          <ion-label> TPoint - Your Ultimate Educational Platform, A silan software product</ion-label>
          <ion-slides pager="true" [options]="slideOpts">
            <ion-slide>
              <div class="w-100">
                <p>Explore diverse courses, from academic subjects to practical skills.</p>
                <p>Learn at your pace with self-paced courses.</p>
                <p>Engaging content with interactive quizzes and exercises.</p>
                <p>Learn from industry experts in their fields.</p>
              </div>
            </ion-slide> <ion-slide>
              <div class="w-100">
                <p>Boost your growth and advance your career.</p>
                <p>Access courses 24/7 on any device.</p>
                <p>Join a supportive learning community.</p>
                <p>Download TPoint now and unlock your potential!</p>
              </div>
            </ion-slide>
        </ion-slides>
      </div>
    </div>
    <div>
      <div class="d-flex customCheckbox">
        <ion-checkbox (ionChange)="onClickShow($event)" [(ngModel)]="isChecked"></ion-checkbox>
        <ion-label class="checkboxLabel">By Signing up you accept our <span (click)="termsOfService('terms')">Terms of
            Service</span> and <span (click)="termsOfService('privacy')">Privacy Policy</span></ion-label>
      </div>
      <ion-button class="primary-btn" [disabled]="!checkedBtn" (click)="onClickStart()">Get Started !</ion-button>
    </div>
  </div>
</ion-content>
