import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { UserDetails } from 'src/app/model/user';
import { UserServiceService } from 'src/app/services/user-service.service';
import { TypesOfUserComponent } from '../modal/types-of-user/types-of-user.component';

@Component({
  selector: 'app-user-type',
  templateUrl: './user-type.component.html',
  styleUrls: ['./user-type.component.scss'],
})
export class UserTypeComponent implements OnInit {
  subscribeUserDetails: any;
  userDetails: UserDetails = new UserDetails();
  checkedBtn = false;
  constructor(
    private router: Router,
    private modalController: ModalController,
    private userService: UserServiceService
  ) { }

  ngOnInit() {
    this.subscribeUserDetails = this.userService.getUserDetails().subscribe((userData) => {
      this.userDetails = userData;
    })
  }



  ionViewWillLeave() {
    this.subscribeUserDetails.unsubscribe();
  }

  onClickShow(event: any) {
    if (event.detail.value) {
      this.userDetails.userType = event.detail.value;
      this.userService.setUserDetails(this.userDetails);
      this.checkedBtn = true;
    }
  }

  onClickProceed() {
    this.router.navigate(['persolanInfo']);
  }

  async clickKnowMore() {
    const modal = await this.modalController.create({
      component: TypesOfUserComponent,
      cssClass: 'centerModal_2',
      componentProps: {
      }
    });
    await modal.present();
  }

}
