import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
//import { FCM } from 'plugins/cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { register } from 'swiper/element/bundle';
import { UserDetails } from './model/user';
import { AuthGuardService } from './services/auth-guard.service';
import { ConnectivityProvider } from './services/connectivity-service';
import { UserServiceService } from './services/user-service.service';
import { UtilServiceService } from './services/util-service.service';
declare var navigator: any;
register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public online: boolean = false;
  public backOnline: boolean = false;
  constructor(private platform: Platform, private router: Router, private storage: Storage,
    // private fcm: FCM,
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private userService: UserServiceService,
    private util: UtilServiceService,
    private splashScreen: SplashScreen,
    private alertController: AlertController,
    private connectivityProvider: ConnectivityProvider,
    private authGuardService: AuthGuardService
  ) {
    this.initializeApp();
    this.setupFCM();
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      this.platform.backButton.subscribeWithPriority(10, () => {
        if (this.router.url.includes('home/tabs/showcase' || 'home/tabs/events' || 'home/tabs/events/subscribedevents')) {
          this.presentAlertConfirm();
        }

      });

      this.validateFirstTimeInstall();
      this.getUserAuth();

    });

    this.connectivityProvider.appIsOnline$.subscribe(online => {
      if (online) {
        this.online = true;
        this.util.showToast('Network Connected', 'success', 'bottom');
      } else {
        this.online = false;
        this.util.showToast('Network  disconnected', 'danger', 'bottom');
      }
    });
  }


  async setupFCM() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        console.log('Sucess ', result);
        PushNotifications.register();
      } else {
        console.log(result);
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        // alert('Push registration success, token: ' + token.value);
        const userDetails:any = this.userService.getUserDetails();
        this.authGuardService.seFCMtToken(token.value);
        userDetails.token = token.value;
        this.userService.setUserDetailsToCollecton(userDetails);
        this.userService.setUserDetails(userDetails);
        // this.userRegisterService.updateToken(userDetails, token.value);
        // userDetails.token = token.value;
        // this.auth.setUserDetails(userDetails);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        // alert('Error on registration: ' + JSON.stringify(error));
        console.log(error);
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        //alert('Push action performed: ' + JSON.stringify(notification));

      }
    );

  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      // header: 'Confirm!',
      message: 'Are you sure you want to exit the app?',
      buttons: [{
        text: 'NO',
        role: 'NO',
        cssClass: 'secondary',
        handler: (blah) => {
          this.router.navigate(['home/tabs']);
        }
      }, {
        text: 'YES',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    });

    await alert.present();
  }



  async validateFirstTimeInstall() {
    // this.storage.get('APP_INSTALLED').then(res => {
    //   alert(res)
    //   if (res && res == 1) {
    //     this.router.navigate(['login']);
    //   } else {
    //     this.router.navigate(['welcome']);
    //   }

    // });

  }




  getUserAuth() {
    this.afAuth.authState.subscribe((user: any) => {
      this.splashScreen.hide();
      if (user && user.uid) {
        const query = this.firestore.collection('users').doc(user.uid);
        query.ref.get().then((respone: any) => {
          if (respone.exists) {
            let userDetails = respone.data();
            this.userService.setUserDetails(userDetails);

            if(!this.authGuardService.token.getValue() && userDetails?.token != null)
            {
              this.authGuardService.seFCMtToken(userDetails.token);
            }

            if (userDetails.userType == 'PARENT') {
              this.router.navigate(['/home/user-listing']);
            }
            else {
              this.getAllSubscribedEvents(userDetails);
              this.userService.getAllEventParamters();
              this.router.navigate(['/home/tabs/events']);
            }
          }
          else {
            let userDetails: UserDetails = new UserDetails();
            userDetails.id = user.uid;
            userDetails.mobileNo = user.phoneNumber;

            if(!userDetails?.userType || userDetails?.userType == "")
            {
              userDetails.userType = 'STUDENT';
            }
            this.userService.setUserDetails(userDetails);
            this.router.navigate(['persolanInfo']);

            // this.router.navigate(['userType']);
          }
        }, err => {
          console.log('err', err);
          this.userService.setUserDetails({} as any);
          this.router.navigate(['/login']);

        })
      }
      else {
        this.userService.setUserDetails({} as any);
        // this.router.navigate(['/login']);
        this.router.navigate(['/welcome']);
      }
    });






  }

  getAllSubscribedEvents(userDetails: any) {
    this.firestore.collection('user_event_subscription', ref => ref
      .where('userId', '==', userDetails.id))
      .valueChanges().subscribe((events: any[]) => {
        let allevents: any[] = [];
        if (null != events && events.length > 0) {
          events.forEach(subscribeRecord => {
            if (null != subscribeRecord && null != subscribeRecord.events) {
              allevents.push(...subscribeRecord.events);
            }
          });
        }
        this.userService.setSubscribedUserEvents(allevents);
      });
  }
}
