import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";


@Injectable({
    providedIn: 'root'
})

export class DateUtilService {
    constructor() {
       // moment.tz.setDefault('asia/kolkata');
    }

    getMonth() {
        return moment().format('MMMM');
    }

    getCurrentDate() {
        let tzDate = moment();
        return tzDate;
    }


    getCurrentMilliSeconds() {
        return moment().valueOf();
    }


    getCurrentDateWithYYYYMMDD() {
        let tzDate = this.getCurrentDate().format('YYYY-MM-DD');
        return tzDate;
    }

    addDayToCurrentData(days: number) {
        return this.getCurrentDate().add(days, 'days').format('YYYY-MM-DD hh:mm A');
    }



    getCurrentTimeWith12hrFormat() {
        let tzDate = this.getCurrentDate().format('hh:mm A');
        return tzDate;
    }

    getCurrentDateWithMinAndSecondFormat() {
        let tzDate = this.getCurrentDate().format('HH:mm:ss');
        return tzDate;
    }



    getTimeDifferenceInMins(eventStartTime: string, eventEndTime: string) {
        var startTime = moment(eventStartTime, 'hh:mm A');
        var endTime = moment(eventEndTime, 'hh:mm A');

        return moment(endTime).diff(moment(startTime), 'minutes');
    }

    getTimeDifferenceInSeconds(eventStartTime: string, eventEndTime: string) {
        var startTime = moment(eventStartTime, 'HH:mm:ss');
        var endTime = moment(eventEndTime, 'HH:mm:ss');

        return moment(endTime).diff(moment(startTime), 'seconds');
    }
    formattTimeDifferenceInSeconds(timeInSeconds: any) {
        return moment.utc(timeInSeconds * 1000).format('HH:mm:ss');
    }

    formatDate(date: string) {
        return moment(date, "YYYY-MM-DD hh:mm A").format('YYYY-MM-DD hh:mm A');
    }



    formatTimeZone(date: string) {
        return moment(date, "YYYY-MM-DD hh:mm A").format('hh:mm A'); //changes by Masud
        //return moment(new Date(date)).format('hh:mm A');
    }

    getDateDifferenceInDays(sDate: string, eDate: string) {
        return moment(new Date(eDate)).diff(moment(new Date(sDate)), "days");
    }

    getTimeDifferenceInHoursWithCurrentTime(eDate: string) {
        var endFormatedDate = moment(eDate, 'YYYY-MM-DD hh:mm A');
        var edate = moment(endFormatedDate).format('YYYY-MM-DD HH:mm');
        var cur = this.getCurrentDate().format('YYYY-MM-DD HH:mm');
        return moment(edate).diff(moment(cur), 'hours');
    }
    getFormatDate(date: string) {
        return moment(date, "DD-MM-YYYY").format('DD/MM/YYYY');
    }
    getFormatDateT(date: string) {
        return moment(date, "DD-MM-YYYY").format();
    }

    sortingBasedOnCreationTime(target: Array<any>) {
        return target.sort((b, a) => moment(a.creationDate).unix() - moment(b.creationDate).unix());
    }

    getCurrentDateWithTime() {
        let tzDate = this.getCurrentDate().format('YYYY-MM-DD hh:mm A');
        return tzDate;
    }

    sortingBasedOnAppearedDate(target: Array<any>) {
        return target.sort((b, a) => moment(a.appearedDate).unix() - moment(b.appearedDate).unix());
    }

    sortingBasedOnEventDate(target: Array<any>) {
        return target.sort((b, a) => moment(a.eventDate).unix() - moment(b.eventDate).unix());
    }

    checkDateInBetween(inputDate: string, startDate: string, endDate: string) {
        return moment(inputDate, 'YYYY-MM-DD').isBetween(moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD'), 'day', '[]');
    }

    checkDateBefore(inputDate: any, eventDate: any) {
        return moment(inputDate, 'YYYY-MM-DD').isBefore(moment(eventDate, 'YYYY-MM-DD'));
    }
    checkDateIsAfter(inputDate: any, eventEndDate: any) {
        return moment(inputDate, 'YYYY-MM-DD').isAfter(moment(eventEndDate, 'YYYY-MM-DD'));
    }

    fomrmatDateToMonthAndDate(eventEndDate: any) {
        return moment(eventEndDate).format("Do MMMM");
    }



    sortingBasedSubscriptionDate(target: Array<any>) {
        return target.sort((b, a) => moment(a.endDate).unix() - moment(b.endDate).unix());
    }

    formatSecondsToTime(e: number) {
        const h = Math.floor(e / 3600).toString().padStart(2, '0'),
            m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
            s = Math.floor(e % 60).toString().padStart(2, '0');

        return h + ':' + m + ':' + s;
    }

    formatSecondsToTimeMS(e: number) {
        const m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
            s = Math.floor(e % 60).toString().padStart(2, '0');

        return m + ':' + s;
    }
    getCurrentEpochTime() {
        return moment().unix();
    }

    getUnixTime(date: string) {
        return moment(date, "YYYY-MM-DD").unix();
    }

}