<ion-content>
  <div class="d-none" id="sign-in-button"></div>
  <div class="inner-page">
    <div>
      <div class="logo">
        <ion-img [src]="'/assets/images/logo.svg'"></ion-img>
      </div>
      <div class="heading text-center">
        <h4>Registration</h4>
        <!-- <span>Enter your {{email ? 'Email id' : 'mobile number'}} , we will send you OTP to verify later</span> -->
      </div>
      <form name="registerForm" #registerForm="ngForm" autocomplete="off">
        <div class="input-content">
          <div class="login-input verify-input">
            <ion-input type="text" name="phoneNo" [(ngModel)]="phoneNo" id="number" id="number"
              placeholder="Mobile Number" minlength="10" maxlength="10" inputmode="tel" required></ion-input>
            <ion-img class="verify-icon" src="/assets/images/verify.svg" *ngIf="registerForm.valid"></ion-img>
          </div>
        </div>
        <div class="btn-content">
          <ion-button class="secondary-btn" [disabled]="!registerForm.valid" (click)="signinWithPhoneNumber($event)">
            continue</ion-button>
        </div>
      </form>
    </div>
  </div>
</ion-content>