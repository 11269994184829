export class Videos {
    public id: string='';
    public userId: string='';
    public userName: string='';
    public userImage: string='';
    public classId:string='';
    public className:string='';
    public boardName:string='';
    public boardId:string='';
    public videoUrl: string='';
    public status: string='';
    public uploadDate: any;
    public categoryName: string='';
    public categoryId: string='';
    public imageUrl: string='';
    public submitForApprovalDate: string='';
    public publishdate: any;
    public publishBy: string='';
    public videoLength: string='';
    public videoDesc: string='';
    public videoText: string='';

}
