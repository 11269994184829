<ion-header>
  <div class="header-toolbar">
    <ion-title *ngIf="type === 'terms'">Terms of service</ion-title>
    <ion-title *ngIf="type === 'privacy'">Privacy Policy</ion-title>
  </div>
</ion-header>
<ion-content>
  <div class="modal-inner" *ngIf="type === 'terms'">

    <h2>AGREEMENT TO TERMS</h2>
    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur repellat soluta facere, delectus eveniet aut repellendus explicabo magnam nesciunt asperiores! Earum fuga maiores ea aliquam quo expedita fugit explicabo quos.
    Tempore pariatur a nulla dolores inventore doloribus eum et eos ipsam illo unde deleniti expedita sequi quibusdam corporis iure, veniam animi ad. A laborum nostrum iste, unde expedita eius laboriosam.
    Quidem impedit delectus illum nihil at labore et dolores voluptatum blanditiis ab deleniti dolor, fuga corporis, cupiditate, distinctio a totam? Cumque aperiam aspernatur, dicta culpa dolorem labore voluptas excepturi? Rerum.
    Animi ea sapiente cupiditate qui consequuntur soluta aut porro neque odio inventore, veniam odit, ut accusamus dolore debitis explicabo hic doloribus eum vero! Cumque, sapiente! Ab assumenda impedit unde quae.
    Quod repellat officiis asperiores repudiandae, sed qui. In cumque, repellat adipisci alias voluptatum nesciunt soluta hic sed earum assumenda amet est possimus. Iure, nisi ullam beatae quia dignissimos similique hic.</p>

    <h4>DESCRIPTION</h4>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab, accusantium. Quibusdam in, aliquam facilis aut quia labore laborum debitis alias laudantium nulla placeat rem! Officiis, quas optio? Nemo, eos rerum. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut dignissimos, consequuntur aliquid ullam repellat vel amet culpa officiis perferendis voluptatum doloremque eius earum vitae dolorum qui exercitationem eum recusandae sint.
    Quasi maxime reiciendis odit expedita nesciunt. Itaque culpa cupiditate sit accusamus velit numquam laboriosam natus dicta! Adipisci a dolore aliquid, dolores, illo quo, autem similique eveniet rem omnis odit quos.
    Id ab illum tenetur facere repudiandae, dolore nostrum beatae provident facilis expedita sunt fugiat vitae aliquam officiis corporis modi delectus esse quae nisi veniam laborum, fuga alias labore quos! Quo?
    Esse neque, ipsa doloremque reprehenderit nobis iste nisi ratione tenetur fugiat ex in maxime unde magnam error impedit sit repellendus repellat sapiente officiis cumque molestiae odit? Accusantium nobis ipsa sit.</p>

    <h4>USER TYPES</h4>
    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam dolorum voluptate facilis eveniet consectetur sapiente exercitationem magnam cum architecto, ea officiis pariatur. Perferendis perspiciatis necessitatibus molestias voluptates eos id nam?
    Ducimus tempora neque ab perspiciatis, magnam rerum quidem earum, nihil quisquam enim quae corrupti veniam sint odio reprehenderit delectus esse asperiores nulla sed explicabo harum? Quasi delectus recusandae repellendus doloribus!
    Odit cum explicabo nemo aspernatur omnis magni perferendis eius, minima aperiam dolorum ullam nobis saepe dolores vero pariatur, asperiores soluta. Corrupti dolore animi amet hic soluta obcaecati, fugiat reprehenderit. Officia.
    Molestiae nobis molestias nulla ipsum totam inventore quas sit vel ipsam consequuntur! Animi, dicta laborum, esse quam ipsam necessitatibus voluptas culpa, reprehenderit aut corrupti atque voluptatem tenetur qui vitae ducimus.</p>

    <h4>ELIGIBILITY</h4>
    <P>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident totam voluptatibus distinctio reiciendis cum magnam quaerat veritatis tenetur id ipsam, adipisci repudiandae similique quia necessitatibus in, commodi explicabo aliquid fugiat!
    Dolore dolor sequi quibusdam est nulla libero temporibus omnis. Culpa corrupti iure consequuntur deserunt, at, tempora iste rerum nesciunt, dicta totam odio officiis. Iure numquam cum vero ducimus, neque ut.
    Officiis amet alias veniam modi quas maiores ea enim quae, beatae perspiciatis accusamus perferendis ducimus eaque, porro ipsa, a asperiores ex molestiae. Dolore consectetur, corrupti quam magni tempora distinctio repellendus?
    Ad error incidunt in ullam unde porro explicabo architecto, nemo et voluptates dolorem ipsa tenetur? Voluptates incidunt asperiores dignissimos ducimus dolor sequi blanditiis ad ipsa facere rerum, necessitatibus eligendi facilis?</P>

    <h4>REGISTRATION</h4>
    <P>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, vitae saepe. Commodi at vero illo unde ab culpa assumenda, molestias cum, fugiat labore repellendus aut. Minima nesciunt maxime quis magni?.</P>
    <ul>
      <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam saepe doloremque eos ipsa voluptatum ad quod veniam corrupti alias consectetur nostrum magnam, suscipit unde. Nihil temporibus veritatis cum minus corporis?</li>
      <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam saepe doloremque eos ipsa voluptatum ad quod veniam corrupti alias consectetur nostrum magnam, suscipit unde. Nihil temporibus veritatis cum minus corporis?</li>
      <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam saepe doloremque eos ipsa voluptatum ad quod veniam corrupti alias consectetur nostrum magnam, suscipit unde. Nihil temporibus veritatis cum minus corporis?</li>
      <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam saepe doloremque eos ipsa voluptatum ad quod veniam corrupti alias consectetur nostrum magnam, suscipit unde. Nihil temporibus veritatis cum minus corporis?</li>
      <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam saepe doloremque eos ipsa voluptatum ad quod veniam corrupti alias consectetur nostrum magnam, suscipit unde. Nihil temporibus veritatis cum minus corporis?</li>

    </ul>

    <h4>PLANS AND SUBSCRIPTION</h4>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam perferendis ex ratione deleniti beatae quae ad soluta incidunt fugit, harum aspernatur non nobis ea, porro, laborum provident nostrum reprehenderit quas.
    Magni, impedit eveniet laudantium excepturi magnam consequuntur veniam voluptates distinctio eligendi, quam dolore quas nemo tempore exercitationem illum iure facilis fugiat omnis labore expedita amet. Cumque alias voluptates aperiam distinctio?
    Unde, dolores corrupti accusantium illo, debitis voluptates aliquam blanditiis animi deleniti ipsam labore error nostrum quidem nam vel fugit aut consequatur architecto molestias reiciendis eius exercitationem? Ut blanditiis quasi iste.
    Ab voluptates doloribus exercitationem, quibusdam vel sunt quas debitis id iure autem quasi dolore deleniti tempora placeat quis praesentium! Molestias vero harum corrupti odit asperiores porro tempore saepe voluptates beatae!</p>
    <h4>REWARD POLICY</h4>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla autem dolores omnis nisi. Incidunt, nostrum voluptate maxime voluptatum quaerat itaque eveniet, inventore esse ullam odit voluptatem doloribus architecto accusantium velit.
    Aperiam magni nemo, ipsum quaerat itaque voluptates repudiandae ipsa vitae corporis, rerum nisi. Architecto fugiat ad error. At, exercitationem expedita atque odio necessitatibus, veritatis nostrum in voluptatibus recusandae labore voluptate?
    Reiciendis iusto at ad explicabo delectus iste architecto molestiae, numquam itaque deleniti autem nesciunt quod, modi sed totam soluta voluptate exercitationem quidem nam error fugiat! Voluptates commodi ipsum quis vel!
    Iure, dicta inventore! Commodi exercitationem dolorum aspernatur illo facere expedita adipisci, temporibus doloremque aliquid iste consequuntur perferendis eaque obcaecati quaerat laboriosam placeat maxime officiis laborum at sapiente quos similique? Laudantium?
    Dolore molestiae, nobis odio aliquam repellat repellendus? Enim eveniet vitae ea! Nihil velit dolore repudiandae tempora doloribus eveniet qui. Amet corrupti laborum numquam, quia maxime dolorum ducimus omnis facere praesentium.
    Soluta quos consectetur, nisi fuga sit incidunt vel eius voluptatum! Nulla itaque facilis velit cum sed quidem officia vitae harum maxime minima quae, consequatur expedita excepturi nisi pariatur rerum libero!</p>


    <h4>ACCESS, PERMISSIONS & RESTRICTIONS</h4>
    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Recusandae adipisci cumque blanditiis id quidem illum, dolor eligendi deleniti? Culpa maiores veniam sint quasi veritatis at nam harum aliquid incidunt labore?
    Perferendis, ipsa? Fugit nemo eligendi velit ad accusantium, non, in praesentium cumque nobis veritatis deserunt eveniet quas nostrum! Ipsa id officia porro ullam quia magnam repudiandae doloribus, cum harum exercitationem.</p>
    <ul>
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit id sint neque ipsum fuga quas minus fugit. Distinctio velit eius provident, tenetur voluptatibus asperiores hic animi praesentium aliquam rem dicta?</li>
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit id sint neque ipsum fuga quas minus fugit. Distinctio velit eius provident, tenetur voluptatibus asperiores hic animi praesentium aliquam rem dicta?</li>
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit id sint neque ipsum fuga quas minus fugit. Distinctio velit eius provident, tenetur voluptatibus asperiores hic animi praesentium aliquam rem dicta?</li>
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit id sint neque ipsum fuga quas minus fugit. Distinctio velit eius provident, tenetur voluptatibus asperiores hic animi praesentium aliquam rem dicta?</li>
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit id sint neque ipsum fuga quas minus fugit. Distinctio velit eius provident, tenetur voluptatibus asperiores hic animi praesentium aliquam rem dicta?</li>
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit id sint neque ipsum fuga quas minus fugit. Distinctio velit eius provident, tenetur voluptatibus asperiores hic animi praesentium aliquam rem dicta?</li>
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit id sint neque ipsum fuga quas minus fugit. Distinctio velit eius provident, tenetur voluptatibus asperiores hic animi praesentium aliquam rem dicta?</li>
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit id sint neque ipsum fuga quas minus fugit. Distinctio velit eius provident, tenetur voluptatibus asperiores hic animi praesentium aliquam rem dicta?</li>


    </ul>

    <h4>USER CONTENT AND CONDUCT</h4>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, eaque illum iure eveniet minima doloribus tempore dolor sed quasi atque, optio dignissimos consectetur nisi quibusdam ab qui neque temporibus rerum.
    Deleniti molestiae, rem iusto, excepturi amet modi repellat at voluptatum libero nemo provident! Culpa tempore quis autem nam assumenda fugiat obcaecati, quod fuga illum quasi minima hic sed ex unde.</p>
    <p class="text-italic">You agree and undertake not to host, display, submit post, upload, update, modify, publish, transmit or share
      any information or content that belongs to the following points:-</p>
    <ul class="disc">
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic culpa ipsa pariatur in nesciunt eos, consequatur necessitatibus ipsum aspernatur delectus modi ex nulla iusto eveniet esse et, itaque molestias tempore?</li>
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic culpa ipsa pariatur in nesciunt eos, consequatur necessitatibus ipsum aspernatur delectus modi ex nulla iusto eveniet esse et, itaque molestias tempore?</li>
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic culpa ipsa pariatur in nesciunt eos, consequatur necessitatibus ipsum aspernatur delectus modi ex nulla iusto eveniet esse et, itaque molestias tempore?</li>
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic culpa ipsa pariatur in nesciunt eos, consequatur necessitatibus ipsum aspernatur delectus modi ex nulla iusto eveniet esse et, itaque molestias tempore?</li>
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic culpa ipsa pariatur in nesciunt eos, consequatur necessitatibus ipsum aspernatur delectus modi ex nulla iusto eveniet esse et, itaque molestias tempore?</li>
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic culpa ipsa pariatur in nesciunt eos, consequatur necessitatibus ipsum aspernatur delectus modi ex nulla iusto eveniet esse et, itaque molestias tempore?</li>
      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic culpa ipsa pariatur in nesciunt eos, consequatur necessitatibus ipsum aspernatur delectus modi ex nulla iusto eveniet esse et, itaque molestias tempore?</li>

    </ul>
    <p class="text-italic">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolores sint sit, nulla aut mollitia, assumenda pariatur debitis veniam porro officiis repellendus. Reiciendis voluptates repudiandae omnis ex facere eum, obcaecati perferendis.</p>
  <ul class="disc">
    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur dolor earum, officiis praesentium quasi sit eveniet, molestias vitae nostrum ipsa tempora! Laudantium magnam delectus magni sint nihil nisi molestias ipsa.</li>
    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur dolor earum, officiis praesentium quasi sit eveniet, molestias vitae nostrum ipsa tempora! Laudantium magnam delectus magni sint nihil nisi molestias ipsa.</li>
    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur dolor earum, officiis praesentium quasi sit eveniet, molestias vitae nostrum ipsa tempora! Laudantium magnam delectus magni sint nihil nisi molestias ipsa.</li>
    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur dolor earum, officiis praesentium quasi sit eveniet, molestias vitae nostrum ipsa tempora! Laudantium magnam delectus magni sint nihil nisi molestias ipsa.</li>
    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur dolor earum, officiis praesentium quasi sit eveniet, molestias vitae nostrum ipsa tempora! Laudantium magnam delectus magni sint nihil nisi molestias ipsa.</li>
    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur dolor earum, officiis praesentium quasi sit eveniet, molestias vitae nostrum ipsa tempora! Laudantium magnam delectus magni sint nihil nisi molestias ipsa.</li>
    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur dolor earum, officiis praesentium quasi sit eveniet, molestias vitae nostrum ipsa tempora! Laudantium magnam delectus magni sint nihil nisi molestias ipsa.</li>
    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur dolor earum, officiis praesentium quasi sit eveniet, molestias vitae nostrum ipsa tempora! Laudantium magnam delectus magni sint nihil nisi molestias ipsa.</li>
  </ul>
  <h4>CONTACT</h4>
  <h2>TO BE PROVIDED BY Client</h2>
  </div>

  <div class="modal-inner" *ngIf="type === 'privacy'">
    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deserunt sunt sequi delectus consectetur temporibus commodi vel? Accusamus temporibus in qui voluptas natus amet dicta, est quos aut consectetur maxime suscipit.
    Sequi sapiente porro perspiciatis recusandae labore mollitia, id quam iusto, cupiditate harum quas, rem non molestias architecto ratione. Dolore nisi inventore reiciendis odit veritatis earum labore. Tempora aliquid nobis placeat!
    Harum tenetur voluptates vitae rem sed. Cumque, sint quas minus quisquam corporis doloremque blanditiis deleniti ea consequatur tenetur ab mollitia aliquam voluptates est sed enim totam libero adipisci voluptatum eius.
    Repellendus aperiam suscipit, nam neque similique cum deserunt magnam repudiandae, autem incidunt ducimus hic, laborum beatae nulla illum sint temporibus? Odio corrupti velit maiores dignissimos excepturi tempore at aperiam quaerat.
    Fuga, beatae accusantium culpa, harum nesciunt, reiciendis possimus eligendi nisi recusandae distinctio corrupti commodi deleniti atque expedita. Quaerat repellendus doloremque delectus eveniet pariatur, esse, tempore similique, voluptates sunt vel omnis?
    Quod labore nobis temporibus id ipsa fugit sint omnis quae vitae illo libero dolorum delectus veritatis incidunt, necessitatibus pariatur quaerat voluptates sapiente dicta quidem, quisquam accusamus? Maxime nihil ut nam?
    Sint tempore consequatur doloremque illo voluptas odit fuga unde adipisci ipsum? Repellat ad placeat sed quia? Soluta fugit earum, ex quae consequuntur nisi, dignissimos eius voluptates maiores explicabo minima? Ad.</p>

    <h4>TYPES OF USER(S)</h4>
    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ea incidunt porro non similique architecto doloremque fugit ipsam obcaecati quidem perferendis illo eius, exercitationem ex iste neque dolores eum! Perspiciatis, ullam.
    Similique quos sit omnis, earum cum veniam ipsum saepe? Ut rerum sit et sed tenetur, minus quibusdam architecto delectus totam deserunt incidunt iste ratione ex. Consequatur quod cupiditate eius modi!
    Quidem minima sunt obcaecati libero quia quo praesentium, dolorum voluptatum laborum quibusdam veritatis nostrum corrupti maxime. Incidunt nam atque blanditiis, tempora velit laboriosam culpa repudiandae error optio, voluptatem omnis deleniti.
    Est necessitatibus dolorum illo esse magnam, accusamus odio corrupti rem nihil molestias laboriosam nesciunt mollitia? Nobis ipsa illo minima optio maiores, et ab animi quod sit, quo possimus ipsum nisi!
    Facere debitis placeat optio unde expedita quas repellendus perferendis beatae pariatur nihil quae, dolore, in quia commodi esse accusantium ipsam incidunt explicabo, inventore ducimus enim sequi! Culpa placeat autem odit.
    Unde consectetur aliquam in atque dicta tempora esse aut deleniti eos nobis dolorem magni recusandae repellendus dolor laudantium numquam, voluptatum cumque rerum beatae sit nihil ut pariatur harum. Fuga, reprehenderit.
    Alias dolores eum eaque corporis sint consectetur commodi aliquid iure cumque autem cum quae sapiente, aut qui labore unde doloribus provident natus laborum, ea necessitatibus aspernatur fuga earum quasi! Amet.</p>

    <h4>CONTACT</h4>
    <h2>TO BE PROVIDED BY Client</h2>

  </div>
</ion-content>
<ion-footer>
  <div class="footer-toolbar">
    <ion-button class="primary-btn" (click)="onClickOk(true)">YES, It's OK !</ion-button>
    <ion-button class="secondary-btn" (click)="onClickClose()">No Thanks !</ion-button>
  </div>
</ion-footer>
