// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyCiBPfvk5jsRPyGn3_nnoKIzmRPGh_fe_w",
    authDomain: "tpoint-logicq.firebaseapp.com",
    projectId: "tpoint-logicq",
    storageBucket: "tpoint-logicq.appspot.com",
    messagingSenderId: "612540411272",
    appId: "1:612540411272:web:cbbf6ef2aa67dec3501e3d",
    measurementId: "G-72WW2TERWX"
  },
  production: true,
  version: '1.1.6',
  razorpayConfig: {
    // key: 'rzp_live_xvAoiBHMFWAdKr'
     key: 'rzp_test_rD4r9gTr7emaSX'
  },
  sms: ['USER_PROFILE_KYC', 'USER_PROFILE_KYC_PENDING', 'USER_REGISTRATION', 'EVENT_SUBSCRIBED', 'EVENT_SCHEDULE', 'USER_PROFILE_CHANGE_REQUEST'],
  push: ['EVENT_COMPLETED', 'USER_PROFILE_KYC_PENDING', 'USER_REGISTRATION', , 'EVENT_SUBSCRIBED', 'EVENT_SCHEDULE', 'USER_PROFILE_CHANGE_REQUEST'],
  domain: "https://starkwizzuser.web.app"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
