<ion-content>
  <div class="inner-page">
    <div class="mb-3">
      <div class="logo">
        <ion-img [src]="'/assets/images/logo.svg'"></ion-img>
      </div>
      <div>
        <ion-label class="heading">You would like to Sign in as</ion-label>
        <div>
          <ion-list class="card-list">
            <ion-radio-group (ionChange)="onClickShow($event)">
              <ion-item>
                <ion-label>Student</ion-label>
                <ion-radio slot="end" mode="ios" value="STUDENT"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>Parent</ion-label>
                <ion-radio slot="end" mode="ios" value="PARENT"></ion-radio>
              </ion-item>
             <!--  <ion-item>
                <ion-label>Teacher</ion-label>
                <ion-radio slot="end" mode="ios" value="teacher"></ion-radio>
              </ion-item> -->
            </ion-radio-group>
          </ion-list>
        </div>
        <div class="text-center mb-2" (click)="clickKnowMore()">
          <ion-button class="clear-btn" fill="clear">
            <ion-icon name="alert-circle"></ion-icon>Know more
          </ion-button>
        </div>
      </div>
    </div>
    <div>
      <ion-button class="primary-btn" [disabled]="!checkedBtn" (click)="onClickProceed()">Proceed</ion-button>
    </div>
  </div>
</ion-content>
