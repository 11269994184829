<ion-content>
  <div class="splash-inner">
    <ion-img [src]="'assets/images/logo.svg'" class="logo"></ion-img>
    <div class="text-center">
      <h4>A Silan Software Product.</h4>
      <span>The <b>Battle</b> of the <b>Bests</b></span>
    </div>
    <div>
      <!-- <ion-img [src]="'assets/icon/screen.gif'" class="logo"></ion-img> -->
    </div>
  </div>
</ion-content>
