import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { NavController } from '@ionic/angular';
import { UserDetails } from 'src/app/model/user';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
  windowWidth: string='';
  showSplash = true;
  constructor(
    private navCtrl: NavController,
    private router: Router,
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
   // private splashScreen: SplashScreen,
    private userService: UserServiceService
  ) { }

  ngOnInit() {
    this.afAuth.authState.subscribe(user => {
      //this.splashScreen.hide();
      if (user && user.uid) {
        const query = this.firestore.collection('users').doc(user.uid);
        query.ref.get().then((respone: any) => {
          if (respone.exists) {
            let userDetails = respone.data();
            this.userService.setUserDetails(userDetails);
            if (userDetails.userType == 'PARENT') {
              this.router.navigate(['/home/user-listing']);
            }
            else {
              this.router.navigate(['/home/tabs/events']);
            }
          }
          else {
            let userDetails: UserDetails = new UserDetails();
            userDetails.id = user.uid;
            userDetails.mobileNo = user.phoneNumber as string;
            this.userService.setUserDetails(userDetails);
            if(!userDetails?.userType || userDetails?.userType=="")
            {
              userDetails.userType = 'STUDENT';
            }
            this.router.navigate(['persolanInfo']);
            
            // this.router.navigate(['userType']);
          }
        }, err => {
          console.log('err', err);
          this.userService.setUserDetails({} as any);
          this.router.navigate(['/login']);

        })
      }
      else {
        this.userService.setUserDetails({} as any);
        // this.router.navigate(['/login']);
        this.router.navigate(['/welcome']);
      }
    })






  }


}
