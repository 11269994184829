import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { IonInput, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { DateUtilService } from 'src/app/common/util/date-util.service';
import { InputCode, OtpDetails } from 'src/app/model/otpcode';
import { UserDetails } from 'src/app/model/user';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UserServiceService } from 'src/app/services/user-service.service';
import { UtilServiceService } from 'src/app/services/util-service.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss'],
})
export class VerifyOtpComponent implements OnInit {
  @Input() recaptcha = {} as any;
  @Input() type = {} as any;
  @Input() email = {} as any;
  @Input() freeTrial?= {} as any;
  @Input() subscriptionType = {} as any;
  @Input() backURL = {} as any;

  maxTime: any = 60;
  expireOtp: boolean = false;
  phoneNo: string = '';
  passcode: InputCode = new InputCode();
  otpcode: number = 0;
  otpDetails: OtpDetails = new OtpDetails();
  userDetails: UserDetails = new UserDetails();
  constructor(
    private router: Router,
    public modalController: ModalController,
    private authService: AuthService,
    private userService: UserServiceService,
    private util: UtilServiceService,
    private firestore: AngularFirestore,
    private storage: Storage,
    private loading: LoadingService,
    private authGuardService: AuthGuardService,
    private dateUtilService: DateUtilService
  ) { }

  async ngOnInit() {
    await this.storage.create();
    this.userService.getUserDetails().subscribe(userDetails => {
      if (userDetails) {
        this.userDetails = userDetails;
        this.phoneNo = userDetails.mobileNo;
      }
    })

    this.startTimer();
    if (this.type == 'EMAIL_VERIFY') {
      this.getEmailOptDetails();
    }
  }


  codeController(event: any, next: IonInput, prev: IonInput) {
    if (event.target.value.length < 1 && prev) {
      prev.setFocus();
    } else if (next && event.target.value.length > 0) {
      next.setFocus();
    } else {
      return 0;
    }
    return 0;
  }

  submit() {
    this.loading.presentLoadingWithoutDuration();
    let passcode = this.passcode.code1 + this.passcode.code2 + this.passcode.code3 + this.passcode.code4 + this.passcode.code5 + this.passcode.code6;
    if (this.type == 'EMAIL_VERIFY') {
      if (this.otpDetails.otpNumber == passcode) {
        this.firestore.collection('user_message_mail').doc(this.otpDetails.id).update({
          status: 'CLOSED'
        });
        let profileType: any = null;
        if (this.freeTrial) {
          profileType = 'FREETRAIL';
        }
        if (!this.userDetails.profileType.includes('FREETRAIL')) {
          this.userDetails.profileType.push(profileType);
        }
        this.userDetails.isEmailVerified = true;
        this.userService.updateUserEmailAfterVerificationToCollecton(this.userDetails, profileType);

        this.modalController.dismiss();
        this.loading.dismiss();
        if (this.subscriptionType == 'EVENT') {
          if (!this.userDetails.profileType.includes('EVENT_SUBSCRIBED')) {
            this.userDetails.profileType.push('EVENT_SUBSCRIBED');
          }

          this.userService.setUserDetails(this.userDetails);
          this.util.showInfoAlert('Your required Information Sucessfully Completed ,Now proceed with your activity.');
          this.router.navigate([this.backURL], { queryParams: { isKyc: true } });
        } else {
          this.userService.setUserDetails(this.userDetails);
          this.router.navigate(['home/dynamo/beforePaid'], { queryParams: { freeTrial: this.freeTrial } });
        }

      }
      else {
        this.util.showToast(('Pease Enter a Valid OTP'), 'danger', 'bottom');
        this.loading.dismiss();
      }
    } else {
      if (passcode) {
        this.authService.enterVerificationCode(passcode).then(userData => {
          if (userData) {
            this.firestore.collection('users').doc(userData.uid).get().subscribe((respone: any) => {
              if (respone.exists) {
                let userDetails = respone.data();
                this.updateFCMToken(userDetails);
                this.userService.setUserDetails(userDetails);
                if (userDetails.userType == 'PARENT') {
                  this.router.navigate(['/home/user-listing']);
                  this.loading.dismiss();
                  this.modalController.dismiss();
                }
                if (userDetails.userType != 'PARENT') {
                  this.router.navigate(['home/tabs']);
                  this.loading.dismiss();
                  this.modalController.dismiss();
                }
              }
              else {
                this.userDetails.creationdate = this.dateUtilService.getCurrentDateWithTime();
                if(!this.userDetails?.userType || this.userDetails?.userType=="")
                {
                  this.userDetails.userType = 'STUDENT';
                }
                this.userService.setUserDetails(this.userDetails);
                this.router.navigate(['persolanInfo']);
                // this.router.navigate(['userType']);
                this.loading.dismiss();
                this.modalController.dismiss();
              }
            }, err => {
              console.log('err', err);
              this.loading.dismiss();

            })
          } else {
            this.util.showToast('No User found,Please try again.', 'danger', 'bottom');
          }
        }, err => {
          this.util.showToast((err), 'danger', 'bottom');
          this.loading.dismiss();
        })
      }
    }



  }

  resend() {
    if (this.type == 'EMAIL_VERIFY') {
      let otpNumber = Math.floor(100000 + Math.random() * 900000).toString();
      this.firestore.collection('user_message_mail').doc(this.otpDetails.id).update({
        otpNumber: otpNumber
      }).then((data) => {
        this.util.showToast(('OTP resend.Please check your mail'), 'success', 'bottom');
        this.maxTime = 60;
        this.startTimer();
      })

    } else {
      this.authService.signInWithPhoneNumber(this.recaptcha, this.phoneNo).then(success => {
        this.maxTime = 60;
        this.startTimer();
        this.passcode.code1 = '';
        this.passcode.code2 = '';
        this.passcode.code3 = '';
        this.passcode.code4 = '';
        this.passcode.code5 = '';
        this.passcode.code6 = '';
      })
    }

  }

  startTimer() {
    setTimeout(x => {
      if (this.maxTime <= 0) { }
      this.maxTime -= 1;
      if (this.maxTime > 0) {
        this.startTimer();
        this.expireOtp = false;
      }

      else {
        this.expireOtp = true;
      }

    }, 1000);


  }

  getEmailOptDetails() {
    const query = this.firestore.collection('user_message_mail', ref => ref
      .where('email', '==', this.email)
      .where('functionType', '==', 'EMAIL_OTP')
      .where('status', '==', 'ACTIVE'));
    query.valueChanges().subscribe((optData: any) => {
      optData.forEach((otp: any) => {
        this.otpDetails = otp;
      });
    });
  }

  updateFCMToken(user: any) {
    this.authGuardService.getFCMToken().subscribe(token => {
      if (null != user && token) {
        this.firestore.collection("users").doc(user.id).update({
          token: token
        }).then(result => {
          console.log('token update');
        });
      }
    });
  }



}
