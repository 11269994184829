export class InputCode {
    code1: string='';
    code2: string='';
    code3: string='';
    code4: string='';
    code5: string='';
    code6: string='';
}

export class OtpDetails{
    id: string='';
    userId: string='';
    type: string='';
    otpNumber: string='';
    status: string='';
    email: string='';
}